import axios from '@/utils/axios'

export function listBaseDictPage(params){
  return axios({
    url:'/api/pc/backstage/baseDict/listBaseDictPage',
    method:'get',
    params
  })
}

// 根据字典值获取内容
export function getDictValueListByDictType(params){
  return axios({
    url:'/api/pc/backstage/baseDict/getDictValueListByDictType',
    method:'get',
    params
  })
}

// 查询岗位中的约束条件
export function getPositionRestrain(params){
  return axios({
    url:'/api/pc/backstage/position/getPositionRestrain',
    method:'get',
    params
  })
}

// 根据岗位和学历查询所学专业
export function getPositionMajor(params){
  return axios({
    url:'/api/pc/backstage/position/getPositionMajor',
    method:'get',
    params
  })
}

// 上传
export function uploadFileOss(data){
  return axios({
    url:'/api/pc/common/uploadFileOss',
    method:'post',
    data
  })
}


// 个人端信息回显
export function getOwnExamineeById(params){
  return axios({
    url:'/api/pc/backstage/examinee/getOwnExamineeById',
    method:'get',
    params
  })
}

// 管理端信息回显
export function getExamineeById(params){
  return axios({
    url:'/api/pc/backstage/examinee/getExamineeById',
    method:'get',
    params
  })
}

// 查询条件中的单位和岗位数据查询
export function getUnitAndPositionList(params){
  return axios({
    url:'/api/pc/backstage/position/getUnitAndPositionList',
    method:'get',
    params
  })
}


