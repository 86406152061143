<template>
  <div class="personalInformationBox">
    <div class="personalInformationContent">
      <div class="personalLeft">
        <div class="personalTitle">个人基本信息</div>

        <div class="basicForm">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <div class="basicFlex">
              <el-form-item label="报考单位">
                <el-input
                  disabled
                  v-model="ruleForm.positionUnit"
                  style="width: 200px"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item class="m-left20" label="报考岗位">
                <el-input
                  disabled
                  v-model="ruleForm.positionName"
                  style="width: 200px"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="basicFlex">
              <div class="positionFormBox">
                <el-checkbox
                    class="checkBox"
                    v-model="checkedObj.checkedEmail"
                    v-if="checkeFlag"
                ></el-checkbox>
                <el-form-item label="电子邮件" prop="email">
                  <el-input
                      v-model="ruleForm.email"
                      style="width: 200px"
                      placeholder="请输入电子邮件"
                      :disabled="examineDetails && !arr[0].checked"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-model="checkedObj.checkedName"
                  v-if="checkeFlag"
                ></el-checkbox>
                <el-form-item class="m-left20" label="姓名" prop="name">
                  <el-input
                    :disabled="examineDetails && !arr[1].checked"
                    style="width: 200px"
                    maxlength="10"
                    v-model.trim="ruleForm.name"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="basicFlex p-leftRight">
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-model="checkedObj.checkedCard"
                  v-if="checkeFlag"
                ></el-checkbox>
                <el-form-item label="身份证号" prop="idCard">
                  <el-input
                    :disabled="
                      (examineDetails && !arr[2].checked) || idCardFlag
                    "
                    maxlength="18"
                    style="width: 200px"
                    v-model.trim="ruleForm.idCard"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-model="checkedObj.checkedBirthday"
                  v-if="checkeFlag"
                ></el-checkbox>
                <el-form-item class="m-left20" label="出生日期" prop="birthday">
                  <el-date-picker
                    value-format="yyyy-M-dd"
                    @change="birthdayChange"
                    :disabled="true"
                    style="width: 200px"
                    v-model="ruleForm.birthday"
                    type="date"
                    placeholder="选择日期"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="basicFlex">
              <div class="positionFormBox">
                <!-- <el-checkbox class="checkBox" v-model="checkedObj.checkedAge" v-if="checkeFlag"></el-checkbox> -->
                <el-form-item label="年龄" prop="age">
                  <el-input
                    maxlength="2"
                    style="width: 200px"
                    v-model="ruleForm.age"
                    disabled
                  ></el-input>
                </el-form-item>
              </div>
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-model="checkedObj.checkedSex"
                  v-if="checkeFlag"
                ></el-checkbox>
                <el-form-item class="m-left20" label="性别" prop="sex">
                  <!-- :disabled="examineDetails && !arr[4].checked" -->
                  <div class="ageBox">
                    <el-radio-group
                      v-model="ruleForm.sex"
                      :disabled="true"
                    >
                      <el-radio label="1" border>男</el-radio>
                      <el-radio label="2" border>女</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
              </div>
            </div>
            <div class="basicFlex p-leftRight">
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-model="checkedObj.checkedCategory"
                  v-if="checkeFlag"
                ></el-checkbox>
                <el-form-item label="考生类别" prop="examineeCategory">
                  <!-- @change="examineeCategoryChange" -->
                  <el-select
                    style="width: 200px"
                    v-model="ruleForm.examineeCategory"
                    :disabled="examineDetails && !arr[6].checked"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in examineeCategoryList"
                      :key="item.dictDesc"
                      :label="item.dictValue"
                      :value="item.dictDesc"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-model="checkedObj.checkedSource"
                  v-if="checkeFlag"
                ></el-checkbox>
                <el-form-item
                  class="m-left20"
                  label="生源地"
                  prop="examineeSource"
                >
                  <el-select
                    v-model="ruleForm.examineeSource"
                    style="width: 200px"
                    :disabled="examineDetails && !arr[7].checked"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in examineeSourceList"
                      :key="item.dictDesc"
                      :label="item.dictValue"
                      :value="item.dictDesc"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="p-leftRight">
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-model="checkedObj.checkedRegister"
                  v-if="checkeFlag"
                ></el-checkbox>
                <el-form-item label="户口所在地" prop="city">
                  <el-input v-model="ruleForm.city" style="width: 100px" :disabled="examineDetails && !arr[8].checked" placeholder="省" />
                  -
                  <el-input v-model="ruleForm.area" style="width: 100px" :disabled="examineDetails && !arr[8].checked" placeholder="市" />
                  -
                  <el-input
                    style="width: 180px"
                    maxlength="50"
                    placeholder="详细地址"
                    v-model.trim="ruleForm.censusRegister"
                    :disabled="examineDetails && !arr[8].checked"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="p-leftRight">
              <div class="positionFormBox">
                <el-checkbox
                    class="checkBox"
                    v-model="checkedObj.checkedNativePlace"
                    v-if="checkeFlag"
                ></el-checkbox>
                <el-form-item label="籍贯" prop="nativePlace">
                  <el-input v-model="ruleForm.nativePlace" :disabled="examineDetails && !arr[9].checked" placeholder="请填写省市区" />
                </el-form-item>
              </div>
            </div>
            <div class="basicFlex p-leftRight">
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-model="checkedObj.checkedLandscape"
                  v-if="checkeFlag"
                ></el-checkbox>
                <el-form-item label="政治面貌" prop="politicalLandscape">
                  <el-select
                    v-model="ruleForm.politicalLandscape"
                    style="width: 200px"
                    :disabled="examineDetails && !arr[10].checked"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in politicalLandscapeList"
                      :key="item.dictDesc"
                      :label="item.dictValue"
                      :value="item.dictDesc"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-model="checkedObj.checkedNation"
                  v-if="checkeFlag"
                ></el-checkbox>
                <el-form-item class="m-left20" label="民族" prop="nation">
                  <el-select
                    v-model="ruleForm.nation"
                    style="width: 200px"
                    :disabled="examineDetails && !arr[11].checked"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in nationList"
                      :key="item.dictDesc"
                      :label="item.dictValue"
                      :value="item.dictDesc"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="basicFlex">
              <div class="positionFormBox">
                <!-- <el-checkbox class="checkBox" v-if="checkeFlag" v-model="checkedObj.checkedPhone"></el-checkbox> -->
                <el-form-item label="移动电话">
                  <el-input
                    maxlength="11"
                    style="width: 200px"
                    disabled
                    v-model="phone"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-if="checkeFlag"
                  v-model="checkedObj.checkedPhoneBak"
                ></el-checkbox>
                <el-form-item class="m-left20" label="备用电话" prop="phoneBak">
                  <el-input
                    maxlength="11"
                    style="width: 200px"
                    :disabled="examineDetails && !arr[13].checked"
                    v-model.trim="ruleForm.phoneBak"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="basicFlex">
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-if="checkeFlag"
                  v-model="checkedObj.checkedGraduateSchool"
                ></el-checkbox>
                <el-form-item label="最高学历毕业院校" prop="graduateSchool">
                  <el-input
                    maxlength="25"
                    style="width: 200px"
                    :disabled="examineDetails && !arr[14].checked"
                    v-model.trim="ruleForm.graduateSchool"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-if="checkeFlag"
                  v-model="checkedObj.checkedGraduateDate"
                ></el-checkbox>
                <el-form-item
                  class="m-left20"
                  label="最高学历毕业时间"
                  prop="graduateDate"
                >
                  <el-date-picker
                    style="width: 200px"
                    value-format="yyyy-MM"
                    :disabled="examineDetails && !arr[15].checked"
                    v-model="ruleForm.graduateDate"
                    type="month"
                    placeholder="选择日期"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="basicFlex p-leftRight">
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-if="checkeFlag"
                  v-model="checkedObj.checkedEducationBackground"
                ></el-checkbox>
                <el-form-item label="最高学历" prop="educationBackground">
                  <el-select
                    v-model="ruleForm.educationBackground"
                    style="width: 200px"
                    :disabled="examineDetails && !arr[16].checked"
                    placeholder="请选择"
                    @change="educationChangeFun"
                  >
                    <el-option
                      v-for="item in educationBackgroundList"
                      :key="item.dictDesc"
                      :label="item.dictValue"
                      :value="item.dictDesc"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-if="checkeFlag"
                  v-model="checkedObj.checkedDiploma"
                ></el-checkbox>
                <el-form-item class="m-left20" label="最高学位" prop="diploma">
                  <el-select
                    v-model="ruleForm.diploma"
                    style="width: 200px"
                    :disabled="examineDetails && !arr[17].checked"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in diplomaList"
                      :key="item.dictDesc"
                      :label="item.dictValue"
                      :value="item.dictDesc"
                    >
                    </el-option>
                  </el-select>
<!--                  <el-input-->
<!--                    maxlength="25"-->
<!--                    style="width: 200px"-->
<!--                    :disabled="examineDetails && !arr[17].checked"-->
<!--                    v-model.trim="ruleForm.diploma"-->
<!--                  ></el-input>-->
                </el-form-item>
              </div>
            </div>
<!--            <div class="basicFlex">-->
<!--              <div class="positionFormBox">-->
<!--                <el-checkbox-->
<!--                  class="checkBox"-->
<!--                  v-if="checkeFlag"-->
<!--                  v-model="checkedObj.checkedDiplomaUnit"-->
<!--                ></el-checkbox>-->
<!--                <el-form-item label="最高学位授予单位" prop="diplomaUnit">-->
<!--                  <el-input-->
<!--                    maxlength="25"-->
<!--                    style="width: 200px"-->
<!--                    :disabled="examineDetails && !arr[18].checked"-->
<!--                    v-model.trim="ruleForm.diplomaUnit"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </div>-->
<!--              <div class="positionFormBox">-->
<!--                <el-checkbox-->
<!--                  class="checkBox"-->
<!--                  v-if="checkeFlag"-->
<!--                  v-model="checkedObj.checkedDiplomaTime"-->
<!--                ></el-checkbox>-->
<!--                <el-form-item-->
<!--                  class="m-left20"-->
<!--                  label="最高学位授予时间"-->
<!--                  prop="graduateDate"-->
<!--                >-->
<!--                  <el-date-picker-->
<!--                    style="width: 200px"-->
<!--                    value-format="yyyy-MM"-->
<!--                    :disabled="examineDetails && !arr[19].checked"-->
<!--                    v-model="ruleForm.diplomaTime"-->
<!--                    type="month"-->
<!--                    placeholder="选择日期"-->
<!--                  ></el-date-picker>-->
<!--                </el-form-item>-->
<!--              </div>-->
<!--            </div>-->
            <div class="p-leftRight">
              <div class="positionFormBox majorInput">
                <el-checkbox
                  class="checkBox"
                  v-if="checkeFlag"
                  v-model="checkedObj.checkedMajor"
                ></el-checkbox>
                <el-form-item label="最高学历所学专业" prop="major">
                  <el-select
                    v-model="ruleForm.major"
                    :loading="loading"
                    @change="majorChange"
                    :disabled="examineDetails && !arr[20].checked"
                    style="width: 100%"
                    placeholder="请选择"
                    filterable
                    @focus="getPositionMajorFun"
                  >
                    <el-option
                      v-for="item in majorList"
                      :key="item.dictDesc"
                      :label="item.dictValue"
                      :value="item.dictDesc"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label=""
                  v-if="ruleForm.major == 99"
                  prop="majorRemark"
                >
                  <el-input
                    maxlength="25"
                    :disabled="examineDetails && !arr[20].checked"
                    v-model.trim="ruleForm.majorRemark"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="basicFlex">
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-if="checkeFlag"
                  v-model="checkedObj.checkedEvaluation"
                ></el-checkbox>
                <el-form-item label="职称" prop="evaluation">
                  <el-input
                    maxlength="25"
                    style="width: 200px"
                    :disabled="examineDetails && !arr[21].checked"
                    v-model.trim="ruleForm.evaluation"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-if="checkeFlag"
                  v-model="checkedObj.checkedEvaluationTime"
                ></el-checkbox>
                <el-form-item
                  class="m-left20"
                  label="评聘时间"
                  prop="evaluationTime"
                >
                  <el-date-picker
                    style="width: 200px"
                    value-format="yyyy-MM"
                    :disabled="examineDetails && !arr[22].checked"
                    v-model="ruleForm.evaluationTime"
                    type="month"
                    placeholder="选择日期"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </div>

<!--            <div class="basicFlex">-->
<!--              <div class="positionFormBox">-->
<!--                <el-checkbox-->
<!--                  class="checkBox"-->
<!--                  v-if="checkeFlag"-->
<!--                  v-model="checkedObj.checkedGradeSchool"-->
<!--                ></el-checkbox>-->
<!--                <el-form-item label="本科毕业院校" prop="gradeSchool">-->
<!--                  <el-input-->
<!--                    maxlength="25"-->
<!--                    style="width: 200px"-->
<!--                    :disabled="examineDetails && !arr[21].checked"-->
<!--                    v-model.trim="ruleForm.gradeSchool"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </div>-->
<!--              <div class="positionFormBox">-->
<!--                <el-checkbox-->
<!--                  class="checkBox"-->
<!--                  v-if="checkeFlag"-->
<!--                  v-model="checkedObj.checkedGradeDate"-->
<!--                ></el-checkbox>-->
<!--                <el-form-item-->
<!--                  class="m-left20"-->
<!--                  label="本科毕业时间"-->
<!--                  prop="gradeDate"-->
<!--                >-->
<!--                  <el-date-picker-->
<!--                    style="width: 200px"-->
<!--                    value-format="yyyy-MM"-->
<!--                    :disabled="examineDetails && !arr[22].checked"-->
<!--                    v-model="ruleForm.gradeDate"-->
<!--                    type="month"-->
<!--                    placeholder="选择日期"-->
<!--                  ></el-date-picker>-->
<!--                </el-form-item>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="p-leftRight">-->
<!--              <div class="positionFormBox majorInput">-->
<!--                <el-checkbox-->
<!--                  class="checkBox"-->
<!--                  v-if="checkeFlag"-->
<!--                  v-model="checkedObj.checkedGradeMajor"-->
<!--                ></el-checkbox>-->
<!--                <el-form-item label="本科所学专业" prop="gradeMajor">-->
<!--                  <el-input-->
<!--                    :disabled="examineDetails && !arr[23].checked"-->
<!--                    v-model.trim="ruleForm.gradeMajor"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item-->
<!--                  label=""-->
<!--                  v-if="ruleForm.gradeMajor == 99"-->
<!--                  prop="gradeMajorRemark"-->
<!--                >-->
<!--                  <el-input-->
<!--                    maxlength="25"-->
<!--                    :disabled="examineDetails && !arr[23].checked"-->
<!--                    v-model.trim="ruleForm.gradeMajorRemark"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </div>-->
<!--            </div>-->
            <div class="p-leftRight">
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-if="checkeFlag"
                  v-model="checkedObj.checkedExperience"
                ></el-checkbox>
                <el-form-item label="教育经历" prop="experience">
                  <el-input
                    placeholder="教育经历（从高中起）"
                    :rows="5"
                    maxlength="300"
                    show-word-limit
                    :disabled="examineDetails && !arr[24].checked"
                    type="textarea"
                    v-model="ruleForm.experience"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="p-leftRight">
              <div class="positionFormBox">
                <el-checkbox
                    class="checkBox"
                    v-if="checkeFlag"
                    v-model="checkedObj.checkedWorkExperience"
                ></el-checkbox>
                <el-form-item label="工作或实习经历" prop="workExperience">
                  <el-input
                      placeholder="工作或实习经历：2019.7  XXX公司  XX职员"
                      :rows="5"
                      maxlength="300"
                      show-word-limit
                      :disabled="examineDetails && !arr[25].checked"
                      type="textarea"
                      v-model="ruleForm.workExperience"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
<!--            <div class="p-leftRight">-->
<!--              <div class="positionFormBox">-->
<!--                <el-checkbox-->
<!--                  class="checkBox"-->
<!--                  v-if="checkeFlag"-->
<!--                  v-model="checkedObj.checkeDactivity"-->
<!--                ></el-checkbox>-->
<!--                <el-form-item label="何时参加过何种党派" prop="activity">-->
<!--                  <el-input-->
<!--                    :rows="2"-->
<!--                    maxlength="50"-->
<!--                    show-word-limit-->
<!--                    :disabled="examineDetails && !arr[18].checked"-->
<!--                    type="textarea"-->
<!--                    v-model="ruleForm.activity"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="p-leftRight">-->
<!--              <div class="positionFormBox">-->
<!--                <el-checkbox-->
<!--                  class="checkBox"-->
<!--                  v-if="checkeFlag"-->
<!--                  v-model="checkedObj.checkedCommend"-->
<!--                ></el-checkbox>-->
<!--                <el-form-item-->
<!--                  label="个人简介"-->
<!--                  prop="commend"-->
<!--                >-->
<!--                  <el-input-->
<!--                    :rows="4"-->
<!--                    maxlength="500"-->
<!--                    show-word-limit-->
<!--                    :disabled="examineDetails && !arr[25].checked"-->
<!--                    type="textarea"-->
<!--                    v-model="ruleForm.commend"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </div>-->
<!--            </div>-->
            <div class="p-leftRight">
              <div class="positionFormBox">
                <el-checkbox
                  class="checkBox"
                  v-if="checkeFlag"
                  v-model="checkedObj.checkedOtherIntroduce"
                ></el-checkbox>
                <el-form-item label="其他需要说明的问题" prop="otherIntroduce">
                  <el-input
                    placeholder="阐述招聘公告中“其他具体要求”中英语水平、职称资格证书等具体信息"
                    :rows="4"
                    maxlength="200"
                    show-word-limit
                    :disabled="examineDetails && !arr[27].checked"
                    type="textarea"
                    v-model="ruleForm.otherIntroduce"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </div>

      <div class="certificatesRight">
        <div class="personalTitle">电子扫描上传</div>
        <!-- 个人证件照 -->
        <div class="personalIdentificationBox">
          <div class="disFlex">
            <div class="requiredTitle">
              <p>个人证件照</p>
              <el-checkbox
                class="checkBox1"
                v-if="checkeFlag"
                v-model="checkedObj.checkedPersonal"
              ></el-checkbox>
            </div>
            <div class="uploadBoxs">
              <upload
                :imgObj="personalObj"
                :size="100"
                :disabled="examineDetails && !arr[28].checked"
                :keys="'personalObj'"
                @changeUpload="changeUpload"
              />
            </div>
            <div></div>
          </div>
          <div class="titleFont" style="width: 340px">证件照</div>
          <div class="describe" style="margin: 10px 10px 0 100px">
            请上传个人近期免冠清晰证件照，格式png或jpg，大小约 20~100K
          </div>
        </div>
        <!-- 身份证扫描件 -->
        <div class="iDCardScanningBox">
          <div class="disFlex">
            <div class="requiredTitle">
              <p>身份证扫描件</p>
              <el-checkbox
                class="checkBox1"
                v-if="checkeFlag"
                v-model="checkedObj.checkedIdCard"
              ></el-checkbox>
            </div>
            <div class="idCardBox">
              <div>
                <div class="uploadBoxs">
                  <upload
                    :imgObj="idCardObjZ"
                    :size="600"
                    :disabled="examineDetails && !arr[29].checked"
                    :keys="'idCardObjZ'"
                    @changeUpload="changeUpload"
                  />
                </div>
                <div class="idCardTitle">正面</div>
              </div>
              <div>
                <div class="uploadBoxs">
                  <upload
                    class="m-left20"
                    :size="600"
                    :imgObj="idCardObjF"
                    :disabled="examineDetails && !arr[29].checked"
                    :keys="'idCardObjF'"
                    @changeUpload="changeUpload"
                  />
                </div>
                <div class="idCardTitle">反面</div>
              </div>
            </div>
          </div>
          <div class="describe" style="margin: 10px 10px 0 100px">
            请上传个人身份证正、反面清晰扫描件，格式png或jpg，大小不超过 600K
          </div>
        </div>
        <!-- 户口本扫描件 -->
        <div class="iDCardScanningBox">
          <div class="disFlex">
            <div class="requiredTitle">
              <p>户口本扫描件</p>
              <el-checkbox
                class="checkBox1"
                v-if="checkeFlag"
                v-model="checkedObj.checkedHousehold"
              ></el-checkbox>
            </div>
            <div class="idCardBox">
              <div>
                <div class="uploadBoxs">
                  <upload
                    :imgObj="householdObjS"
                    :size="600"
                    :disabled="examineDetails && !arr[30].checked"
                    :keys="'householdObjS'"
                    @changeUpload="changeUpload"
                  />
                </div>
                <div class="idCardTitle">首页</div>
              </div>
              <div>
                <div class="uploadBoxs">
                  <upload
                    class="m-left20"
                    :size="600"
                    :disabled="examineDetails && !arr[30].checked"
                    :imgObj="householdObjG"
                    :keys="'householdObjG'"
                    @changeUpload="changeUpload"
                  />
                </div>
                <div class="idCardTitle">本人页</div>
              </div>
            </div>
          </div>
          <div class="describe" style="margin: 10px 10px 0 100px">
            请上传个人户口本首页、本人页清晰扫描件，格式png或jpg，大小不超过 600K
          </div>
        </div>
        <!-- 学历学位证明件 -->
        <div class="iDCardScanningBox">
          <div class="disFlex">
           <div class="requiredTitle">
              <p>学历学位证明件</p>
              <el-checkbox
                class="checkBox1"
                v-if="checkeFlag"
                v-model="checkedObj.checkedAcademic"
              ></el-checkbox>
           </div>
            <div class="idCardBox" style="margin-right: 25px">
              <div v-if="ruleForm.examineeCategory != 1">
                <div class="uploadBoxs">
                  <upload
                    :imgObj="freshStudentsObjL"
                    :size="600"
                    :disabled="examineDetails && !arr[31].checked"
                    :keys="'freshStudentsObjL'"
                    @changeUpload="changeUpload"
                  />
                </div>
                <div class="idCardTitle">学历证</div>
              </div>
              <div v-if="ruleForm.examineeCategory != 1">
                <div class="uploadBoxs">
                  <upload
                    class="m-left20"
                    :size="600"
                    :disabled="examineDetails && !arr[31].checked"
                    :imgObj="freshStudentsObjW"
                    :keys="'freshStudentsObjW'"
                    @changeUpload="changeUpload"
                  />
                </div>
                <div class="idCardTitle">学位证</div>
              </div>
              <div v-if="ruleForm.examineeCategory == 1">
                <div class="uploadBoxs">
                  <upload
                    :size="600"
                    :disabled="examineDetails && !arr[31].checked"
                    :imgObj="freshStudentsObjL"
                    :keys="'freshStudentsObjL'"
                    @changeUpload="changeUpload"
                  />
                </div>
                <div class="idCardTitle">学籍在线验证报告</div>
              </div>
              <div v-if="ruleForm.examineeCategory == 1">
                <div class="uploadBoxs">
                  <upload
                    :size="600"
                    class="m-left20"
                    :disabled="examineDetails && !arr[31].checked"
                    :imgObj="freshStudentsObjW"
                    :keys="'freshStudentsObjW'"
                    @changeUpload="changeUpload"
                  />
                </div>
                <div class="idCardTitle">其他</div>
              </div>
            </div>
          </div>
          <div class="describe" style="margin: 10px 10px 0 100px">
            请上传毕业证、学位证或国外学历学位认证书清晰扫描件，2023年应届高校毕业生可上传"学籍在线验证报告"，格式PNG或JPG，大小不超过600K
          </div>
        </div>
        <!-- 应届生证明件 -->
        <div class="iDCardScanningBox">
          <div class="disFlex">
            <div class="requiredTitle">
              <template v-if="(ruleForm.positionId === 37 || ruleForm.positionId === 38 || ruleForm.positionId === 39)">
                <p>应届生证明件</p>
              </template>
              <template v-else>
                <div>应届生证明件</div>
              </template>

              <el-checkbox
                class="checkBox1"
                v-if="checkeFlag"
                v-model="checkedObj.checkedQualification"
              ></el-checkbox>
            </div>
            <div class="idCardBox" style="margin-right: 25px">
              <div>
                <div class="uploadBoxs">
                  <upload
                    :imgObj="freshStudentsObjY"
                    :size="3"
                    :disabled="examineDetails && !arr[32].checked"
                    :keys="'freshStudentsObjY'"
                    @changeUpload="changeUpload"
                  />
                </div>
                <div class="idCardTitle">就业推荐表</div>
              </div>
              <div>
                <div class="uploadBoxs">
                  <upload
                    class="m-left20"
                    :size="600"
                    :disabled="examineDetails && !arr[32].checked"
                    :imgObj="freshStudentsObjC"
                    :keys="'freshStudentsObjC'"
                    @changeUpload="changeUpload"
                  />
                </div>
                <div class="idCardTitle">成绩单</div>
              </div>
            </div>
          </div>
          <div class="describe" style="margin: 10px 10px 0 100px">
            请上传就业推荐表、成绩单（学校就业主管部门盖章）清晰扫描件，格式png或jpg，大小不超过 3M
          </div>
        </div>
        <!-- 其他资格文件  v-if="ruleForm.examineeCategory==5" -->
        <div class="iDCardScanningBox">
          <div class="disFlex">
            <div class="requiredTitle">
              <div>其他资格文件</div>
              <el-checkbox
                  class="checkBox1"
                  v-if="checkeFlag"
                  v-model="checkedObj.checkedReturnees"
              ></el-checkbox>
            </div>
            <div class="idCardBox">
              <div>
                <div class="uploadBoxs">
                  <upload :imgObj="returneesObj" :size="600" :disabled="examineDetails&&!arr[33].checked" :keys="'returneesObj'" @changeUpload="changeUpload" />
                </div>
<!--                <div class="idCardTitle">社会保险个人权益</div>-->
              </div>
              <div>
                <div class="uploadBoxs">
<!--                  <upload class="m-left20" :imgObj="certificateFile" :size="600" :disabled="examineDetails&&!arr[32].checked" :keys="'certificateFile'" @changeUpload="changeUpload" />-->
                  <upload class="m-left20" :imgObj="commitmentLetter" :size="600" :disabled="examineDetails&&!arr[33].checked" :keys="'commitmentLetter'" @changeUpload="changeUpload" />
                </div>
<!--                <div class="idCardTitle">英语资格证</div>-->
              </div>
            </div>
          </div>
          <div class="describe" style="margin:10px 10px 0 100px">
            2021和2022年应届高校毕业生请提供签字版"本人无社保记录"承诺书，与身份证合并扫描上传（<span style="cursor: pointer;color: blue;text-decoration:underline;" @click="showMapeXample">如图示</span>），格式png或jpg，大小不超过 600K
          </div>
        </div>
        <!-- 报名表扫描件 -->
        <div class="iDCardScanningBox">
          <div class="disFlex">
            <div class="requiredTitle">
              <p>报名表扫描件</p>
              <el-checkbox
                class="checkBox1"
                v-if="checkeFlag"
                v-model="checkedObj.checkedApplication"
              ></el-checkbox>
            </div>
            <div class="idCardBox">
              <div>
                <div class="uploadBoxs">
                  <upload
                    :imgObj="applicationFormObj"
                    :size="1"
                    :disabled="examineDetails && !arr[34].checked"
                    :keys="'applicationFormObj'"
                    @changeUpload="changeUpload"
                  />
                </div>
                <div class="idCardTitle">签字扫描件</div>
              </div>
              <div></div>
            </div>
          </div>
          <div class="describe" style="margin: 10px 10px 0 100px">
            请先保存信息，再使用“报名表打印预览”功能打印纸质报名表并进行签字，上传签字后的报名表；格式png或jpg,大小不超过3M
          </div>
        </div>
      </div>
    </div>

    <div class="examineBox" v-if="examineFlag">
      <div class="examineExplain" v-if="examineDetailsFlag">
        <p>问题信息项</p>
        <div>
          <el-input
            type="textarea"
            disabled
            :rows="3"
            placeholder=""
            v-model="ruleForm.problemItem"
          ></el-input>
        </div>
      </div>
      <div class="examineExplain">
        <p>审核说明</p>
        <div>
          <el-input
            type="textarea"
            :disabled="examineDetailsFlag"
            :rows="3"
            placeholder="请输入"
            v-model="ruleForm.auditMsg"
          ></el-input>
        </div>
      </div>
      <div class="examineConclusion basicForm">
        <p>审核结论</p>
        <div class="ageBox">
          <el-radio
            v-model="applyState"
            label="0"
            :disabled="examineDetailsFlag"
            border
            >通过</el-radio
          >
          <el-radio
            v-model="applyState"
            label="2"
            :disabled="examineDetailsFlag"
            border
            >待完善</el-radio
          >
          <el-radio
            v-model="applyState"
            label="1"
            :disabled="examineDetailsFlag"
            border
            >不通过</el-radio
          >
        </div>
      </div>
    </div>

    <!-- 展示图例 -->
    <el-dialog append-to-body :visible.sync="dialogVisible" width="30%">
      <img width="100%" src="@/assets/letterOfCommitment.jpg" alt="">
    </el-dialog>
  </div>
</template>
<script>
import upload from "@/components/upload.vue";
import { mapGetters } from "vuex";
import {
  getPositionRestrain,
  getOwnExamineeById,
  getExamineeById,
  getDictValueListByDictType,
  getPositionMajor,
} from "@/api/index";

export default {
  components: {
    upload,
  },
  props: ["type", "id", "positionId"],
  data() {
    var regTest = (rule, card, callback) => {
      var year = parseInt(card.substring(6, 10));
      var rn;
      var reg;
      if ((year % 400 == 0 || year % 100 != 0) && year % 4 == 0) {
        //闰年
        reg =
          /^\d{6}(18|19|20)?\d{2}((0[1]|0[3-9]|1[012])(0[1-9]|[1-2][0-9]|3[01]))|((02)(0[1-9]|1[0-9]|2[0-9]))\d{3}(\d|X|x)$/;
        rn = "闰年";
      } else {
        //平年
        reg =
          /^\d{6}(18|19|20)?\d{2}((0[1]|0[3-9]|1[012])(0[1-9]|[1-2][0-9]|3[01]))|((02)(0[1-9]|1[0-9]|2[0-8]))\d{3}(\d|X|x)$/;
        rn = "平年";
      }
      if (card.length != "18") {
        return callback(new Error("身份证号不够18位"));
      }
      var result = reg.test(card);
      if (!result) {
        return callback(new Error("身份证号有误"));
      } else {
        let idCardFun = (val) => {
          let iden = val;
          let sex = null;
          let birth = null;
          let myDate = new Date("2023-12-31");
          let month = myDate.getMonth() + 1;
          let day = myDate.getDate();
          let age = 0;
          if (val.length === 18) {
            //年龄以2023年12月31日计算为准  myDate.getFullYear()
            age = 2022 - iden.substring(6, 10) - 1;
            sex = iden.substring(16, 17);
            birth =
              iden.substring(6, 10) +
              "-" +
              iden.substring(10, 12) +
              "-" +
              iden.substring(12, 14);
            if (
              iden.substring(10, 12) < month ||
              (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)
            )
              age++;
          }
          if (sex % 2 === 0) sex = "2";
          else sex = "1";
          this.ruleForm.sex = sex;
          this.ruleForm.age = age;
          this.ruleForm.birthday = birth;
        };
        idCardFun(card);
        return callback();
      }
    };
    var checkedRegisterFun = (rule, value, callback) => {
      if (
        !this.ruleForm.city ||
        !this.ruleForm.censusRegister ||
        !this.ruleForm.area
      ) {
        return callback(new Error("请完善户口所在地"));
      } else {
        callback();
      }
    };
    return {
      checked: false,
      checkedObj: {},
      loading: false,
      // 个人证件照
      personalObj: {},
      // 身份证扫描照
      idCardObjZ: {},
      idCardObjF: {},
      // 户口本扫描照
      householdObjS: {},
      householdObjG: {},
      // 应届生证明件
      freshStudentsObjY: {},
      freshStudentsObjC: {},
      freshStudentsObjL: {},
      freshStudentsObjW: {},
      // 资格证
      returneesObj: {},
      certificateFile: {},
      commitmentLetter: {},

      // 报名表扫描件
      applicationFormObj: {},
      areaList: [
        {
          value: "东城区",
          label: "东城区",
        },
        {
          value: "西城区",
          label: "西城区",
        },
        {
          value: "朝阳区",
          label: "朝阳区",
        },
        {
          value: "丰台区",
          label: "丰台区",
        },
        {
          value: "海淀区",
          label: "海淀区",
        },
        {
          value: "石景山区",
          label: "石景山区",
        },
        {
          value: "顺义区",
          label: "顺义区",
        },
        {
          value: "昌平区",
          label: "昌平区",
        },
        {
          value: "门头沟区",
          label: "门头沟区",
        },
        {
          value: "房山区",
          label: "房山区",
        },
        {
          value: "大兴区",
          label: "大兴区",
        },
        {
          value: "通州区",
          label: "通州区",
        },
        {
          value: "怀柔区",
          label: "怀柔区",
        },
        {
          value: "平谷区",
          label: "平谷区",
        },
        {
          value: "密云区",
          label: "密云区",
        },
        {
          value: "延庆区",
          label: "延庆区",
        },
      ], // 区集合
      cityList: [
        {
          value: "北京市",
          label: "北京市",
        },
      ], //  城市
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      ruleForm: {
        name: "",
        idCard: "",
        birthday: "",
        age: "",
        sex: "",
        examineeCategory: "",
        examineeSource: "",
        censusRegister: "",
        city: "",
        area: "",
        politicalLandscape: "",
        nativePlace: "",
        nation: "",
        phoneBak: "",
        graduateSchool: "",
        graduateDate: "",
        educationBackground: "",
        diploma: "",
        major: "",
        majorRemark: "",
        gradeMajor: "",
        gradeMajorRemark: "",
        diplomaTime: "",
        diplomaUnit: "",
        gradeSchool: "",
        gradeDate: ""
      },
      rules: {
        email: [{required: true, message: "请输入电子邮件", trigger: "blur"},{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        idCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          { required: true, message: "", trigger: "blur", validator: regTest },
        ],
        // birthday: [
        //   { required: true, message: "请选择出生日期", trigger: "change" },
        // ],
        // age: [
        //   { required: true, message: '请输入年龄', trigger: 'blur' }
        // ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        examineeCategory: [
          { required: true, message: "请选择考生类别", trigger: "change" },
        ],
        examineeSource: [
          { required: true, message: "请选择生源", trigger: "change" },
        ],
        city: [
          { required: true, message: "请完善户口所在地", trigger: "change" },
          { validator: checkedRegisterFun, trigger: "change" },
        ],
        nativePlace: [
          {required: true, message: "请填写籍贯", trigger: "blur"}
        ],
        politicalLandscape: [
          { required: true, message: "请选择政治面貌", trigger: "change" },
        ],
        nation: [{ required: true, message: "请选择民族", trigger: "change" }],
        graduateSchool: [
          { required: true, message: "请输入毕业院校", trigger: "blur" },
        ],
        graduateDate: [
          { required: true, message: "请选择毕业时间", trigger: "change" },
        ],
        gradeMajor: [
          { required: true, message: "请输入本科所学专业", trigger: "change" },
        ],
        educationBackground: [
          { required: true, message: "请选择学历", trigger: "change" },
        ],
        diploma: [{ required: true, message: "请输入最高学位", trigger: "blur" }],
        major: [
          { required: true, message: "请选择所学专业", trigger: "change" },
        ],
        majorRemark: [
          { required: true, message: "请输入最高学历所学专业", trigger: "blur" },
        ],
        gradeMajorRemark: [
          { required: true, message: "请输入本科所学专业", trigger: "blur" },
        ],
        gradeSchool: [
          { required: true, message: "请输入毕业院校", trigger: "blur" },
        ],
        gradeDate: [
          { required: true, message: "请选择毕业时间", trigger: "change" },
        ],
        experience: [
          { required: true, message: "请输入教育经历", trigger: "blur" },
        ],
        workExperience: [
          { required: true, message: "请输入工作或实习经历", trigger: "blur" },
        ],
        otherIntroduce: [
          { required: true, message: "请输入其他需要说明的问题", trigger: 'blur' },
        ],
        diplomaUnit: [
          { required: true, message: "请输入最高学位授予单位", trigger: "blur" },
        ],
        commend: [
          { required: true, message: "请输入个人简介", trigger: "blur" },
        ]
      },
      arr: [
        {
          key: "checkedEmail",
          value: "电子邮件"
        },
        {
          key: "checkedName",
          value: "姓名",
        },
        {
          key: "checkedCard",
          value: "身份证号",
        },
        {
          key: "checkedBirthday",
          value: "出生日期",
        },
        {
          key: "checkedAge",
          value: "年龄",
        },
        {
          key: "checkedSex",
          value: "性别",
        },
        {
          key: "checkedCategory",
          value: "考生类别",
        },
        {
          key: "checkedSource",
          value: "生源地",
        },
        {
          key: "checkedRegister",
          value: "户口所在地",
        },
        {
          key: "checkedNativePlace",
          value: "籍贯",
        },
        {
          key: "checkedLandscape",
          value: "政治面貌",
        },
        {
          key: "checkedNation",
          value: "民族",
        },
        {
          key: "checkedPhone",
          value: "移动电话",
        },
        {
          key: "checkedPhoneBak",
          value: "备用电话",
        },
        {
          key: "checkedGraduateSchool",
          value: "最高学历毕业院校"
        },
        {
          key: "checkedGraduateDate",
          value: "最高学历毕业时间"
        },
        {
          key: "checkedEducationBackground",
          value: "最高学历"
        },
        {
          key: "checkedDiploma",
          value: "最高学位"
        },
        {
          key: "checkedDiplomaUnit",
          value: "最高学位授予单位"
        },
        {
          key: "checkedDiplomaTime",
          value: "最高学位授予时间"
        },
        {
          key: "checkedMajor",
          value: "最高学历所学专业"
        },
        {
          key: 'checkedEvaluation',
          value: "职称"
        },
        {
          key: "checkedEvaluationTime",
          value: "评聘时间"
        },
        // {
        //   key: "checkedGradeSchool",
        //   value: "本科毕业院校"
        // },
        // {
        //   key: "checkedGradeDate",
        //   value: "本科毕业时间"
        // },
        {
          key: "checkedGradeMajor",
          value: "本科所学专业"
        },
        {
          key: "checkedExperience",
          value: "教育经历",
        },
        {
          key: "checkedWorkExperience",
          value: "工作或实习经历"
        },
        {
          key: "checkedCommend",
          value: "个人简介",
        },
        {
          key: "checkedOtherIntroduce",
          value: "其他需要说明的问题",
        },
        {
          key: "checkedPersonal",
          value: "个人证件照",
        },
        {
          key: "checkedIdCard",
          value: "身份证扫描件",
        },
        {
          key: "checkedHousehold",
          value: "户口本扫描件",
        },
        {
          key: "checkedAcademic",
          value: "学历学位证明件",
        },
        {
          key: "checkedQualification",
          value: "应届生证明件",
        },
        {
          key: "checkedReturnees",
          value: "其他资格文件",
        },
        {
          key: "checkedApplication",
          value: "报名表扫描件",
        }
      ],
      examineFlag: false, //
      examineDetails: false, //详情
      checkeFlag: false, //审核需要勾选
      examineDetailsFlag: false, //审核是否禁用
      idCardFlag: false,
      diplomaList: [], //学位
      educationBackgroundList: [], //学历
      examineeCategoryList: [], //考生类别
      examineeSourceList: [], //生源
      nationList: [], //民族
      majorList: [], //专业
      politicalLandscapeList: [], //政治面貌
      phone: "",
      applyState: "", //审核结论
      dialogVisible: false, // 如图示 弹框
    };
  },
  computed: {
    ...mapGetters(["signupObj"]),
  },
  mounted() {
    if (this.$ownToken) {
      this.getPositionRestrainFun();
    } else {
      this.getDictDesc();
    }
    // 修改的时候，不能修改身份证号
    if (this.id) {
      this.idCardFlag = true;
    }
    /**
     * type为1代表是审核页面
     * type为2代表是详情
     * type为3代表是暂存信息
     * type为空代表新增
     */
    console.log(this.type);
    if (this.type == 1) {
      this.examineFlag = true;
      this.examineDetails = true;
      this.checkeFlag = true;
      this.getDetailsFun();
    } else if (this.type == 2) {
      this.examineDetails = true;
      this.examineDetailsFlag = true;
      this.examineFlag = true;
      this.getDetailsFun();
    } else if (this.type == 3) {
      this.getDetailsFun();
    } else if (this.type == 4) {
      this.getDetailsFun();
      this.examineFlag = true;
      this.examineDetailsFlag = true;
      this.examineDetails = true;
    } else {
      this.ruleForm.positionId = this.signupObj.id;
      this.ruleForm.recruitId = this.signupObj.recruitId;
      this.ruleForm.positionName = this.signupObj.name;
      this.ruleForm.positionType = this.signupObj.type;
      this.ruleForm.positionUnit = this.signupObj.unit;
      // 查询岗位中的约束条件
    }
  },
  methods: {
    //当学历修改时 所学专业框清空
    educationChangeFun(){
      this.ruleForm.major = "";
    },

    // 选择学历后 查询所学专业
    getPositionMajorFun(val) {
      if (!this.ruleForm.educationBackground) {
        this.$message.error("请先选择学历!");
        return;
      }
      this.loading = true;
      let parm = {
        id: this.positionId
          ? this.positionId
          : this.signupObj.id
          ? this.signupObj.id
          : this.ruleForm.positionId,
        ownToken: this.$ownToken,
        eduId: this.ruleForm.educationBackground,
      };
      getPositionMajor(parm).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          this.majorList = res.data.majorList;
        }
      });
    },
    // 根据字典获取信息
    getDictDesc() {
      // 民族
      getDictValueListByDictType({ dictType: "mct_base_nation" }).then(
        (res) => {
          if (res.status == 200) {
            this.nationList = res.data;
          }
        }
      );
      // 考生类别
      getDictValueListByDictType({
        dictType: "mct_base_examinee_category",
      }).then((res) => {
        if (res.status == 200) {
          this.examineeCategoryList = res.data;
        }
      });
      // 考生来源
      getDictValueListByDictType({ dictType: "mct_base_examinee_source" }).then(
        (res) => {
          if (res.status == 200) {
            this.examineeSourceList = res.data;
          }
        }
      );
      // 政治面貌
      getDictValueListByDictType({
        dictType: "mct_base_political_landscape",
      }).then((res) => {
        if (res.status == 200) {
          this.politicalLandscapeList = res.data;
        }
      });
      // 学历
      getDictValueListByDictType({
        dictType: "mct_base_education_background",
      }).then((res) => {
        if (res.status == 200) {
          this.educationBackgroundList = res.data;
        }
      });
      // 学位
      getDictValueListByDictType({ dictType: "mct_base_diploma" }).then(
        (res) => {
          if (res.status == 200) {
            this.diplomaList = res.data;
          }
        }
      );
      // 专业
      // getDictValueListByDictType({dictType:'mct_base_major'}).then(res=>{
      //   if(res.status == 200){
      //     this.majorList = res.data;
      //   }
      // })
    },
    examineeCategoryChange() {
      if (this.ruleForm.examineeCategory == 1) {
        this.returneesObj = {};
      } else {
        this.freshStudentsObjY = {};
        this.freshStudentsObjC = {};
        this.freshStudentsObjL = {};
        this.freshStudentsObjW = {};
      }
    },
    // 保存后，不能修改身份证号
    seticCard() {
      this.idCardFlag = true;
    },
    // 回显信息
    getDetailsFun() {
      let data = {
        id: this.id,
      };
      let fn;
      // 判断是管理端，还是个人端
      if (this.$ownToken) {
        fn = getOwnExamineeById;
        data.ownToken = this.$ownToken;
      } else {
        fn = getExamineeById;
      }
      fn(data).then((res) => {
        if (res.status == 200) {
          if (res.data) {
            this.ruleForm = res.data;
            // 处理返回的下拉框为数字
            this.ruleForm.sex = this.ruleForm.sex
              ? String(this.ruleForm.sex)
              : "";
            this.ruleForm.examineeCategory = this.ruleForm.examineeCategory
              ? String(this.ruleForm.examineeCategory)
              : "";
            this.ruleForm.examineeSource = this.ruleForm.examineeSource
              ? String(this.ruleForm.examineeSource)
              : "";
            this.ruleForm.politicalLandscape = this.ruleForm.politicalLandscape
              ? String(this.ruleForm.politicalLandscape)
              : "";
            this.ruleForm.nation = this.ruleForm.nation
              ? String(this.ruleForm.nation)
              : "";
            this.ruleForm.educationBackground = this.ruleForm
              .educationBackground
              ? String(this.ruleForm.educationBackground)
              : "";
            this.ruleForm.diploma = this.ruleForm.diploma
              ? String(this.ruleForm.diploma)
              : "";
            this.ruleForm.major = this.ruleForm.major
              ? String(this.ruleForm.major)
              : "";
            this.ruleForm.gradeMajor = this.ruleForm.gradeMajor
              ? String(this.ruleForm.gradeMajor)
              : "";
            this.phone = res.data.phone;
            this.applyState = String(res.data.applyState);

            if (res.data.problemItem && this.type == 4) {
              let proImteList = res.data.problemItem.split("，");
              for (let i = 0; i < proImteList.length; i++) {
                let item = proImteList[i];
                for (let k = 0; k < this.arr.length; k++) {
                  let attr = this.arr[k];
                  if (item == attr.value) {
                    attr.checked = true;
                    break;
                  }
                }
              }
              console.log(this.arr);
            }

            // 回显图片
            this.$nextTick(() => {
              this.personalObj = {
                url: res.data.photo,
              };
              this.idCardObjZ = {
                url: res.data.frontCard,
              };
              this.idCardObjF = {
                url: res.data.backCard,
              };
              this.householdObjS = {
                url: res.data.firstResidence,
              };
              this.householdObjG = {
                url: res.data.ownResidence,
              };
              this.freshStudentsObjY = {
                url: res.data.recommend,
              };

              this.freshStudentsObjC = {
                url: res.data.reportCard,
              };
              this.freshStudentsObjL = {
                url: res.data.educationPhoto,
              };
              this.freshStudentsObjW = {
                url: res.data.diplomaPhoto,
              };
              this.returneesObj = {
                url: res.data.degreeCertificate,
              };
              this.applicationFormObj = {
                url: res.data.entryForm,
              };
              this.certificateFile = {
                url: res.data.certificateFile,
              };
              this.commitmentLetter = {
                url: res.data.commitmentLetter
              }
            });

            // 回显所学专业
            this.getPositionMajorFun();
          }
        }
      });
    },
    // 查询岗位中的约束条件
    getPositionRestrainFun() {
      let data = {
        id: this.positionId ? this.positionId : this.signupObj.id,
        ownToken: this.$ownToken,
      };
      getPositionRestrain(data).then((res) => {
        if (res.status == 200) {
          this.diplomaList = res.data.diplomaList;
          this.educationBackgroundList = res.data.educationBackgroundList;
          this.examineeCategoryList = res.data.examineeCategoryList;
          this.examineeSourceList = res.data.examineeSourceList;
          this.nationList = res.data.nationList;
          this.politicalLandscapeList = res.data.politicalLandscapeList;
          this.majorList = res.data.majorList;
          this.phone = res.data.phone;
        }
      });
    },
    // 出生日期
    birthdayChange(val) {
      if (val) {
        // 获取当前日期
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let num = year * 12 + month;

        // 出生日期
        let arr = val.split("-");
        let birth = Number(arr[0]) * 12 + Number(arr[1]);

        this.ruleForm.age = Math.floor((num - birth) / 12);
      } else {
        this.ruleForm.age = "";
      }
    },
    // 上传
    changeUpload(key, value) {
      this[key] = value;
    },
    // 专业
    majorChange(val) {
      if (val != 99) {
        this.ruleForm.majorRemark = "";
      }
    },
    // 专业
    gradeMajorChange(val) {
      if (val != 99) {
        this.ruleForm.gradeMajorRemark = "";
      }
    },
    // 如图示
    showMapeXample() {
      console.log('如图示');
      this.dialogVisible = true
    }
  },
};
</script>
