<template>
  <div class="uploadBox">
    <el-upload :disabled="disabled" :class="{hide:hideUpload}" :file-list="fileList" accept=".jpg,.png"
    :on-change="fileChange" action="string" list-type="picture-card" :limit="1"
    :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :auto-upload="false">

      <i slot="default" class="el-icon-plus"></i>
    </el-upload>

    <el-dialog append-to-body :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import {uploadFileOss} from '@/api/index'
export default {
  /**
   * imgObj  是存放图片对象
   * keys 是对象的名字
   */
  props:['imgObj','keys','disabled','size'],
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      hideUpload:false,
      fileList:[]
    }
  },
  watch:{
    'imgObj':function(val){
      if(val.url){
        this.fileList = [{url:val.url}]
        this.hideUpload = true;
      }else{
        this.fileList = []
        this.hideUpload = false;
      }
    }
  },
  methods:{
    fileChange(file){
      console.log(file.size / 1024 / 1024);
      let isLt2M = false;
      if(this.size==100){
        isLt2M = file.size / 1024 / 1024 < 0.1
      }else if(this.size==600){
        isLt2M = file.size / 1024 / 1024 < 0.6
      }else if(this.size==1){
        isLt2M = file.size / 1024 / 1024 < 1
      }else if(this.size==3){
        isLt2M = file.size / 1024 /1024 < 3
      }else if(this.size==5){
        isLt2M = file.size / 1024 / 1024 < 5
      }else if(this.size==10){
        isLt2M = file.size / 1024 / 1024 < 10
      }
      if(!isLt2M){
        let str = '';
        if(this.size==100){
          str = '100k'
        }else if(this.size==600){
          str = '600k'
        }else if(this.size==1){
          str = '1M'
        }else if(this.size==3){
          str = '3M'
        }else if(this.size==5){
          str = '5M'
        }else if(this.size==10){
          str = '10M'
        }
        this.fileList = [];
        this.$message.error(`上传图片大小不能超过 ${str}!`);
        return
      }
      let formData = new FormData();
      formData.append('ownToken',this.$ownToken)
      formData.append('file',file.raw)
      formData.append('type',1)
      uploadFileOss(formData).then(res=>{
        if(res.status == 200){
          this.$emit('changeUpload',this.keys,{url:res.data});
        }
      })
      this.hideUpload = true;
    },
    handleRemove(file) {
      this.fileList = [];
      this.$emit('changeUpload',this.keys,{});
      this.hideUpload = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      console.log(this.size);
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      return isJPG && isLt2M;
    }
  }
}
</script>
<style lang="scss" scoped>
  /deep/.el-dialog{
    margin-left: 49% !important;
  }
</style>
